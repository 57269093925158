import request from '../utils/request';

export const getPageList = (query) => {
    return request({
        url: '/sys/regionSalesPolicy/getPageList',
        method: 'POST',
        data:query
    });
};  
export const insertPolicyImages = (query) => {
    return request({
        url: '/sys/regionSalesPolicy/insertPolicyImages',
        method: 'POST',
        data:query
    });
};  
export const deleteImage = (query) => {
    return request({
        url: `/sys/regionSalesPolicy/delete/${query}`,
        method: 'POST',
    });
};  
//api/sys/regionSalesPolicy/updatePolicyState
export const updatePolicyState = (query) => {
    return request({
        url: '/sys/regionSalesPolicy/updatePolicyState',
        method: 'POST',
        data:query
    });
};  