<template>
  <div>

    <!--    表头,搜索框等-->
    <div style="margin-bottom: 20px">
      <el-button type="primary" style="margin-left:20px" @click="this.addDialog=true">添加图片</el-button>
      <el-select placeholder="请选择类型" v-model="query.regionFlag" style="margin:0 10px">
        <el-option label="全部" :value="0"></el-option>
        <el-option label="销售图集" value="1"></el-option>
        <el-option label="课程安排表" value="2"></el-option>
        <el-option label="满分学员" value="3"></el-option>
        <el-option label="家长锦旗" value="4"></el-option>
      </el-select>
      <el-select v-model="query.areaId" placeholder="请选择城市" class="handle-select mr10" clearable>
        <el-option
            v-for="(item,index) in regionList" :key="index"
            :label="item.name"
            :value="item.id"/>
      </el-select>
      <el-button type="primary" icon="Search" style="margin: 10px" @click="query.pageIndex=1,getDataList()">搜索
      </el-button>
    </div>

    <!--    表格数据-->
    <el-table :data="dataList" border class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              :row-style="{height:3+'px'}"
              :cell-style="{padding:3+'px'}"
              v-loading="tableLoading"
    >
      <el-table-column prop="regionName" label="图集适用城市" width="110"></el-table-column>
      <el-table-column prop="regionFlag" label="类型">
        <template v-slot="scope">
          <div>
            {{
              scope.row.regionFlag == 4 ? '家长锦旗' : scope.row.regionFlag == 1 ? '销售图集' : scope.row.regionFlag == 2 ? '课程安排表' : scope.row.regionFlag == 3 ? '满分学员' : scope.row.regionFlag == 5 ? '集团报表' : ''
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="图集">
        <template v-slot="scope">
          <div v-if="scope.row.regionFlag==1">
            {{ }}
            <el-image
                style="height: 50px;"
                :src="scope.row.policyHeaderImage"
                :preview-src-list="[scope.row.policyHeaderImage]"
            >
              <template #error>
                <div class="image-slot">
                  <span class="demonstration">暂无图片</span>
                </div>
              </template>
            </el-image>
          </div>
          <div v-if="scope.row.regionFlag!=1">
            <el-image
                style="height: 50px;"
                :src="scope.row.policyHeaderImage"
                :preview-src-list="[scope.row.policyHeaderImage]"
            >
              <template #error>
                <div class="image-slot">
                  <span class="demonstration">暂无图片</span>
                </div>
              </template>
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="图集创建时间" width="180"></el-table-column>
      <el-table-column prop="startTime" label="开始施行时间" width="180"></el-table-column>
      <el-table-column prop="endTime" label="活动截止时间" width="180"></el-table-column>
      <el-table-column prop="coachName" label="图集提交人"></el-table-column>
      <el-table-column prop="policyState" label="状态">
        <template v-slot="scope">
          <el-switch
              v-model="scope.row.policyState"
              style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
              active-value="开启"
              inactive-value="关闭"
              disabled
          />
        </template>
      </el-table-column>
      <el-table-column label="图集审核状态">
        <template v-slot="scope">
          <div>
            {{
              scope.row.verifyState == 0 ? '待审核' : scope.row.verifyState == 1 ? '审核中' : scope.row.verifyState == 2 ? '审核通过' : scope.row.verifyState == 3 ? '拒绝' : ''
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="policyRemarks" label="申请说明-原由" filter-multiple></el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button
              type="text"
              style="color:red;"
              @click="delDialogVisible = true,delTempId=scope.row.id"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--    删除提示框-->
    <el-popover :visible="delDialogVisible" :width="160" placement="right-start">
      <p>您确定要删除吗?</p>
      <div style="text-align: right; margin: 0">
        <el-button size="small" text @click="delDialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="deleteContact(1)">
          确定
        </el-button>
      </div>

    </el-popover>

    <!--    分页-->
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="total"
          @current-change="handlePageChange"
      />
    </div>

    <!--    新增图集弹框-->
    <el-dialog
        v-model="addDialog"
        title="添加政策"
        @close="clearAddPhoto"
    >
      <el-form style="padding:20px" label-width="100px">
        <el-row>
          <el-col :span="10">
            <el-form-item label="图片类型">
              <el-select placeholder="类型" v-model="addPhoto.regionFlag">
                <el-option label="销售图集" value="1"></el-option>
                <el-option label="课程安排表" value="2"></el-option>
                <el-option label="满分学员" value="3"></el-option>
                <el-option label="家长锦旗" value="4"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="城市类型">
              <el-select v-model="addPhoto.areaId" placeholder="城市" class="handle-select mr10">
                <el-option
                    v-for="(item,index) in regionList" :key="index"
                    :label="item.name"
                    :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="活动开始时间">
              <el-date-picker
                  v-model="addPhoto.startTime"
                  type="date"
                  placeholder="活动开始时间"
                  :size="size"
                  format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD"
              />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="活动结束时间">
              <el-date-picker
                  v-model="addPhoto.endTime"
                  type="date"
                  placeholder="活动结束时间"
                  :size="size"
                  format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
          </el-col>
          <el-col :span="10">
            <el-form-item label="上传图片">
              <el-upload
                  ref='upload'
                  class="upload"
                  :http-request="uploadCardOmit"
                  :before-upload="beforeAvatarUpload"
                  :on-remove="handleRemoveMin"
                  :class="{ uoloadSty: addPhoto.policyHeaderImage == null ? true : false, disUoloadSty: addPhoto.policyHeaderImage != null ? true : false }"
                  :limit=1
                  list-type="picture-card">
                <el-button icon="Plus" text/>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="备注说明">
              <el-input
                  v-model="addPhoto.policyRemarks"
                  :rows="2"
                  type="textarea"
                  placeholder="请输入联系内容"/>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <template #footer>
        <el-button @click="addDialog=false">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {
  getPageList, insertPolicyImages, deleteImage,
  // updatePolicyState
} from '@/api/policy'
import {selectAreaByExpress} from "@/api/area";
import {uploadImage} from '@/api/helpManual'

export default {
  data() {
    return {
      //满分学员hearderImg
      addPhoto: {
        policyRemarks: '',//	销售图集申请说明-原由
        startTime: '',//开始施行时间
        endTime: '',//活动截止时间
        areaId: null,//图集适用城市ID
        regionFlag: null,//1销售图集,2课程安排表,3满分学员,4家长锦旗
        policyBodyImages: [],//销售图集,内容介绍长图
        policyTitle: '',//销售图集标题
        policyContent: '',//销售图集正文 ,
        verifyState: 2,//图集审核状态：0待审核1审核中2审核通过3被拒绝
        policyHeaderImage: null,//销售图集封面图
        policyState: '开启',//状态
        coachId: Number(localStorage.getItem('coachId')),
        createTime: ''
      },
      regionList: [],//城市列表 其实是areaList
      query: {
        areaId: null,
        pageIndex: 1,
        pageSize: 10,
        policyTitle: "",
        regionFlag: 0
      },//搜索入参
      total: 0, //dataList的总记录条数
      delDialogVisible: false,
      delTempId: null,
      dataList: [], //列表数据 集合
      addDialog: false   //添加弹框
    }
  },
  methods: {
    //删除图片
    deleteContact() {
      //隐藏删除确认提示
      this.delDialogVisible = false;
      //执行删除
      deleteImage(this.delTempId).then(res => {

        //重新加载数据 给予提示
        if (res.code === 200) {
          this.getDataList()
          this.$message.success('删除成功')
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //清空 图片
    clearAddPhoto() {
      let ojb = {
        policyRemarks: '',//	销售图集申请说明-原由
        startTime: '',//开始施行时间
        endTime: '',//活动截止时间
        regionId: null,//图集适用城市ID
        regionFlag: null,//1销售图集,2课程安排表,3满分学员,4家长锦旗
        policyBodyImages: [],//销售图集,内容介绍长图
        policyTitle: '',//销售图集标题
        policyContent: '',//销售图集正文 ,
        verifyState: 1,//图集审核状态：0待审核1审核中2审核通过3被拒绝
        policyHeaderImage: null,//销售图集封面图
        policyState: '开启',//状态
        coachId: Number(localStorage.getItem('coachId')),
        createTime: ''
      }
      this.addPhoto = ojb
    },
    //分页加载数据
    getDataList() {
      //搜索即隐藏全部弹框
      this.delDialogVisible = false;

      getPageList(this.query).then(res => {
        if (res.code == 200) {
          this.dataList = res.data.records
          //加载分页数据
          //分页total
          this.total = res.data.total;
          console.info(this.dataList)
          // this.total = this.dataList.total
          console.log(this.total + "总条数")

        } else {
          this.$message.error(res.message)
        }
      })
      selectAreaByExpress().then(res => {
        if (res.code == 200) {
          this.regionList = res.data
        } else {
          this.$message.error("获取城市数据失败");
        }
      })
    },
    //处理分页点击
    handlePageChange(e) {
      this.query.pageIndex = e
      this.getDataList()
    },
    //选取日期
    getDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      this.addPhoto.createTime = formattedDateTime
    },
    //确认导入
    confirm() {
      this.getDate()
      insertPolicyImages(this.addPhoto).then(res => {
        if (res.code == 200) {
          this.addDialog = false
          this.$message.success('添加成功')
          this.getDataList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //上传省略图片
    async uploadCardOmit(file) {
      this.addPhoto.policyHeaderImage = await this.uploadImg(file)
      this.addPhoto.policyBodyImages.push(this.addPhoto.policyHeaderImage)
      this.addPhoto.policyBodyImages = JSON.stringify(this.addPhoto.policyBodyImages)
    },
    //选中图片
    async uploadImg(file) {
      let url = null
      const data = new FormData()
      data.append(
          'multipartFile', file.file
      )
      data.append(
          'flag', 'policyHeaderImage'
      )
      data.append(
          'fileName', file.file.name
      )
      await uploadImage(data).then(res => {
        if (res.code == 200) {
          url = res.data
        } else {
          this.$message.warning('图片上传失败');
        }
      })
      return url
    },
  },
  created() {
    //加载数据
    this.getDataList()
  },
}
</script>
<style>
.disUoloadSty .el-upload--picture-card {
  display: none !important; /* 上传按钮隐藏 */
}

.el-upload--picture-card {
  width: 80px;
  height: 80px;
}

.el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

.el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

.el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

.el-table__cell {
  position: static !important;
}
</style>
